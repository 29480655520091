<template>
    <!-- <v-container class='mt-8'>
      <v-row justify="center">
        <v-col cols="4">
          <v-btn depressed block color="primary" @click="showAcceptModal()">Accept</v-btn>
        </v-col>
        <v-col cols="4">
          <v-btn depressed block color="primary" @click="showDeclineModal()">Decline</v-btn>
        </v-col></v-row
      >
    </v-container> -->
  <div >
    <div class="loader" id="loader"></div>
    <div>
      <div class="buttonContainer">
        <CButton @click="showAcceptModal()" ref="acceptQuoteButton" class="btn-syncmusic quoteButton" disabled="true" id="acceptButton">Accept</CButton>
        <CButton @click="showDeclineModal()" ref="declineQuoteButton" class="btn-syncmusic quoteButton"  disabled="true" id="declineButton">Decline</CButton>
      </div>
      <div id="pdf" style="margin-right:25%;margin-left:25%;width:50%" ></div>
      <div ref="textDisplay"></div>
    </div>
    <div>
      <CModal
      ref="acceptModal"
      hide-footer
      title="Accept Quote"
      size="lg"
      color="success"
      :show.sync= "acceptModal">
      <div>You are about to accept the quote as it is. If you wish to attach a purchase order, please click the "Upload Purchase Order" button below.<br></div>
      <CTextarea placeholder = "If you wish to make a comment or give additionnal information to your salesperson, please write it here" ref="acceptReason" id="acceptReason"></CTextarea>
      <CInputFile placeholder ="Choose a file"  ref="purchaseOrder" id="purchaseOrder" @change="uploadFile()"></CInputFile>
      <button class="float-right" @click="confirmAccept">Accept</button>
      <button class="float-right" @click="hideAcceptModal">Cancel</button>
    </CModal>
    </div>
    <div>
      <CModal
      hide-footer
      title="Decline Quote"
      color="danger"
      size="lg"
      ref="declineModal"
      :show.sync= "declineModal">
      <div>You are about to decline this quote. Please use the text box below to explain your reasons, so that a salesperson may contact you again on this subject. <br></div>
      <CTextarea placeholder = "Please indicate the reasons that led you to decline this quote..." ref="declineReason" id="declineReason"></CTextarea>
      <button class="float-right" @click="confirmDecline">Decline</button>
      <button class="float-right" @click="hideDeclineModal">Cancel</button>
    </CModal>
    </div>
  </div>
</template>

<style>.modal-footer {
  display:none
}
.quoteButton{
  margin:2%;
  width: 20%;
  height: 10%;
  align-content: center;

}
.buttonContainer{
  width:100%;
  text-align: center
}

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #db3434;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>

<script>


export default {

  name: "QuoteDisplay",
  data() {
    return {
      pdfContent:"",
      acceptModal:false,
      declineModal:false,
      pdfKey:0
    };
  },
  mounted: function() {
    this.fetchQuote();

  },
  methods: {
    fetchQuote() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      this.$http
        .get(
          "/api/quote?crypted_string=" +
            encodeURIComponent(urlParams.get("crypted_string")) +
            "&idconnection=" +
            urlParams.get("idconnection")
        )
        .then(response => {
          
          document.getElementById("pdf").innerHTML = `<embed id="pdfEmbedder"  width=\'100%\' height=\'1200\' allowfullscreen src="data:application/pdf;base64,${response.data.body}" type="application/pdf" />`
          document.getElementById("loader").style.display="none"
          document.getElementById("acceptButton").disabled=false
          document.getElementById("acceptButton").classList.remove("disabled");
          document.getElementById("declineButton").disabled=false
          document.getElementById("declineButton").classList.remove("disabled");

  
        }).catch(function (error) {
              document.getElementById("pdf").innerHTML = `<div style="padding-right: 20%; padding-left:20%">Your quote was not found. Either it was already accepted or declined, or your link is incorrect.</div>`
              document.getElementById("loader").style.display="none"
          }); 
    },
    showAcceptModal() {
     this.acceptModal=true
    },
    hideAcceptModal() {
      this.acceptModal=false
    },
    showDeclineModal() {
      this.declineModal=true
    },
    hideDeclineModal() {
      this.declineModal=false
    },
    confirmAccept() {
      document.getElementById("loader").style.display="block"
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      let postObject = {
        idconnection: urlParams.get("idconnection"),
        crypted_string: urlParams.get("crypted_string"),
        comment : document.getElementById("acceptReason").value

      };
      let file = document.getElementById("purchaseOrder").files[0];
      if (file) {
        postObject.filename = file.name.split("\\").pop()
        if (!window.fileUploading) {
          alert(
            "Your purchase order is still being uploaded. Please wait for a few seconds"
          );
          return;
        }
        postObject.purchaseOrder = window.fileContent;
      }

      this.$http.post("/api/quote/accept", postObject).then( response => {
        document.getElementById("pdf").innerHTML = `<div style="padding-right: 20%; padding-left:20%">The quote was properly accepted. You will be recontacted soon.</div>`
        document.getElementById("loader").style.display="none"
          document.getElementById("acceptButton").disabled=true
          document.getElementById("acceptButton").classList.add("disabled");
          document.getElementById("declineButton").disabled=true
          document.getElementById("declineButton").classList.add("disabled");
          this.acceptModal=false

      }).catch( error => {
        document.getElementById("pdf").innerHTML = `<div style="padding-right: 20%; padding-left:20%">An error occurred while accepting your quote. Please refresh the page in a few minutes and try again.</div>`
        document.getElementById("loader").style.display="none"

      });
    },
    confirmDecline() {
      document.getElementById("loader").style.display="block"
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      let postObject = {
        idconnection: urlParams.get("idconnection"),
        crypted_string: urlParams.get("crypted_string"),
        comment : document.getElementById("declineReason").value
      };
      

      this.$http.post("/api/quote/decline", postObject).then( response => {
        document.getElementById("pdf").innerHTML = `<div style="padding-right: 20%; padding-left:20%">The quote was properly declined. You will be recontacted soon.</div>`
        document.getElementById("loader").style.display="none"
        document.getElementById("acceptButton").disabled=true
        document.getElementById("acceptButton").classList.add("disabled");
        document.getElementById("declineButton").disabled=true
        document.getElementById("declineButton").classList.add("disabled");
        this.declineModal=false
      }).catch( error => {
        document.getElementById("pdf").innerHTML = `<div style="padding-right: 20%; padding-left:20%">An error occurred while declining your quote. Please refresh the page in a few minutes and try again.</div>`
        document.getElementById("loader").style.display="none"

      });

    },
    uploadFile() {
      window.fileUploading = false;
      let file = document.getElementById("purchaseOrder").files[0];
      var r = new FileReader();
      r.onload = function(e) {
        window.fileContent = btoa(e.target.result);
        window.fileUploading = true;
      };
      r.readAsDataURL(file);
    }
  }
};
</script>
